import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Myth vs. Fact: debunking veteran hard and soft skills",
  "author": "Anthony",
  "date": "2018-03-09T06:26:52.000Z",
  "categories": ["Veteran Hiring"],
  "slug": "myth-vs-fact-debunking-veteran-hard-and-soft-skills",
  "draft": false,
  "meta_title": "Myth vs. Fact: debunking veteran hard and soft skills"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Today, many corporate leaders are in search of veterans that will bring real value to the workplace. According to a survey report from CareerBuilder, `}<a parentName="p" {...{
        "href": "https://www.shrm.org/ResourcesAndTools/hr-topics/talent-acquisition/Pages/Hiring-Veterans.aspx"
      }}>{`one-third of surveyed employers`}</a>{` reported they are actively recruiting veterans over the next year, up from 27 percent in last year’s survey. Estimates are that by the year 2023 there will be `}<a parentName="p" {...{
        "href": "https://www.shrm.org/resourcesandtools/hr-topics/talent-acquisition/pages/viewpoint-hiring-veterans-separating-fact-from-fiction.aspx"
      }}>{`3.5 million military veterans`}</a>{` in the U.S. workforce.`}</p>
    <p>{`While this is an exciting initiative for companies seeking to leverage skilled and talented Veterans in their workplace, it’s also challenging. Many hiring managers and recruiters don't understand veterans’ experience and the related hard and soft skills — which likely stems back to the myths civilians have about military veterans.`}</p>
    <p>{`Let’s now discuss some common veteran misconceptions and uncover how hiring managers can strengthen their understanding of the `}<em parentName="p">{`real skills`}</em>{` veterans can bring to civilian jobs.`}</p>
    <p><strong parentName="p">{`Myth #1: All veterans serve in combat.`}</strong></p>
    <p>{`Many civilians (and HR professionals) immediately associate all veteran experience with combat — but there are a plethora of jobs in the military that don’t involve combat. According to the Department of Defense, less than 20 percent of service members serve in front-line combat roles.`}</p>
    <p>{`In fact, military jobs are categorized into more than 7,000 occupational specialty codes, from radio operator to pilot and tower equipment installer to logistician to procurement clerk and mechanic, just to name a few. That adds a laundry list of both hard and soft skills to the mix, and it’s crucial that hiring managers understand vets’ real qualifications and experiences.`}</p>
    <p><strong parentName="p">{`Myth #2: Military skills aren’t transferable to civilian jobs.`}</strong></p>
    <p>{`You’ve undoubtedly heard that `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/why-hiring-veterans-can-reduce-talent-acquisition-and-training-costs"
      }}>{`hiring veterans is valuable`}</a>{` because of their leadership, teamwork, values, resiliency, focus on mission, accomplishments, etc. While this is all true — and will benefit your workplace— veterans also possess many hard skills that directly transfer to jobs in the civilian world.`}</p>
    <p>{`The over 200,000 veterans transitioning out of military service each year are bringing hard skills to industries such as healthcare, aviation, finance, logistics, and administration. Because of the training they received in their military careers, veterans are qualified to fill roles such as Patient Care Technician, Registered Nurse, Biomedical Technician, and Clinical Manager.`}</p>
    <p>{`In this case, there is a cost reduction associated with training and skill-building, as veterans already have the skills needed to get to work.`}</p>
    <p><strong parentName="p">{`Myth #3:`}</strong>{` `}<strong parentName="p">{`All veterans have PTSD.`}</strong></p>
    <p>{`A lot of people think that all veterans have post-traumatic stress disorder (PTSD), making them "unstable" and "unreliable" in the civilian work setting.`}</p>
    <p>{`In reality, 8 percent of all Americans suffer from PTSD, and the number of military veterans with PTSD is relatively low when compared to the total number of those who have served. Hiring managers should not generalize veterans and assume right off the bat that they’re unfit for fast-paced, and often high stress, civilian positions. In actuality, veteran skills enable success in competitive environments.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Setting the Record Straight`}</em></strong></p>
    <p>{`Veteran hard and soft skills can greatly impact organizations that value integrity, commitment, and accountability. Hiring managers should keep in mind that veterans are capable of succeeding in roles where independent thinking and self-motivation are critical. Veterans’ soft skills like determination, adaptability, and leadership allow them to succeed in challenging, competitive civilian roles.`}</p>
    <p>{`HR professionals can reframe the way they comprehend veteran skillsets by asking as many questions as possible, engaging their colleagues to further their knowledge on military skills, and analyzing their perceptions and beliefs.`}</p>
    <p>{`Understanding the real value of veterans in the workplace can provide your company with a wealth of opportunity. To learn more about how to improve your understanding of veteran skills for a veteran to civilian resume, request a call with a dedicated member of our team.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      